import {Injectable} from '@angular/core';
import {PlanCopyDto} from '@simplifi/main/planner/create-plan/models';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CopyHelperService {
  private readonly copySubject = new BehaviorSubject<PlanCopyDto>(
    new PlanCopyDto(),
  );
  copyPlan = this.copySubject.asObservable();
  public copyById(data: PlanCopyDto) {
    this.copySubject.next(data);
  }

  private readonly planVersionRestoreSubject = new BehaviorSubject<PlanCopyDto>(
    new PlanCopyDto(),
  );
  planVersionRestore = this.planVersionRestoreSubject.asObservable();
  public restoreVersion(data: PlanCopyDto) {
    this.planVersionRestoreSubject.next(data);
  }

  public complete() {
    this.copySubject.complete();
    this.planVersionRestoreSubject.complete();
  }
}
