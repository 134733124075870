<div class="el-breadcrumb">
  <span class="el-breadcrumb__item">
    <span class="el-breadcrumb__inner">
      <a (click)="navigatePlanner()" href="#">Campaign Plans</a>
    </span>
    <i class="el-breadcrumb__separator el-icon-arrow-right"></i>
  </span>
  <span class="el-breadcrumb__item" aria-current="page">
    <span class="el-breadcrumb__inner"> Edit Campaign Plan </span>
    <i class="el-breadcrumb__separator el-icon-arrow-right"></i>
  </span>
</div>
