import {environment} from '@simplifi/env/environment';
import {IAdapter, IApiService, PostAPICommand} from '../../api';

export class GetTokenCommand<T> extends PostAPICommand<T> {
  constructor(apiService: IApiService, adapter: IAdapter<T>) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.tenantUserFacade}/auth/access-token`,
    );
  }
}
