export class Organization {
  id!: string;
  key!: string;
  name!: string;
  pname?: string;
  address?: string;
  parentOrgId?: string;
  parentTenantId?: string;
  status!: number;
  website?: string;
  createdOn?: Date | string;
}
