export const Messages = {
  successMsg: 'Success!',
  tenantRegistered:
    'Organization admin has been created successfully, please login.',
  deletePlanMessage: 'Are you sure, you want to delete the plan(s) ?',
  deleteAllPlansMessage: 'Are you sure, you want to delete all the plans ?',
  deletePlanSuccessMessage: 'Plan(s) deleted successfully',
  deleteOrganization: `Do you really want to delete this organization?`,
  discardCreatePlanChanges: `Do you want to discard the changes made?`,
  switchOrganization: `Do you really want to switch this organization?`,
  tenantSwitchedSuccessfully: `Tenant switched successfully.`,
  orgDeletedSuccessFully: `Organization deleted successfully.`,
  userDeletedSuccessFully: `Organization user deleted successfully.`,
  userInvitedSuccessFully: `Invitation sent successfully.`,
  deleteUserMessage: 'Are you sure, you want to delete the user(s) ?',
  deleteAllUsersMessage: 'Are you sure, you want to delete all the users ?',
  deleteUserSuccessMessage: 'User(s) deleted successfully',
  planCreateSuccessMessage: 'Plan details saved successfully',
  planEditSuccessMessage: 'Plan details updated successfully',
  locationChange:
    'Changing selection will delete all previously selected locations.',
  budgetUpdated: 'Plan budget updated successfully',
  changesNeedsToBeSave:
    'Please ensure that plan changes are saved before proceeding.',
  createPlanNavDisableToolTip:
    'After saving all plan changes, you can navigate to the next section.',
  createPlanMandatoryDisableToolTip:
    'Please ensure all mandatory fields are filled.',
  resetKeywordText: 'Are you sure you want to reset the keywords data?',
  addressableKeywordText:
    'Are you sure you want to reset the addressable data?',
  resetZtvZipCodeText: 'Are you sure you want to reset the ZTV zip code data?',
  resetContextualText: 'Are you sure you want to reset the contextual data?',
  keywordWarningText:
    'Data added in suggestion box will be wiped off. Are you sure you want to continue?',
  cancelProcess:
    'Are you sure you want to stop the process of saving the details?',
  noZipCodeFound: 'No zip code found with given search criteria.',
  deleteKeywordText: 'Are you sure you want to remove the keyword(s)?',
  deleteZtvZipCodeText: 'Are you sure you want to remove the ZTV zip code(s)?',
  deleteContextualText: 'Are you sure you want to remove the contextual(s)?',
  deleteAddressableText: 'Are you sure you want to remove the demographic(s)?',
  deleteKeywordSuccess: 'Keyword(s) removed successfully!',
  deleteZtvZipCodeSuccess: 'ZipCode(s) deleted successfully!',
  deleteContextualSuccess: 'Contextual removed successfully.',
  deleteAddressableSuccess: 'Demographics removed successfully.',
  formValidationFailed: `Form validation failed.`,
  validationFailed: `Validation Failed.`,
  detailsSavedSuccessFully: `Details saved successfully.`,
  success: `Success`,
  invalidEmailFormat: `Please enter valid format of the email address example(john@gmail.com)`,
  planCopySuccess: `New copy of the plan is created successfully with name `,
  planVersionUpdateSuccess: `Plan version updated successfully.`,
  planCopyConfirmation: `Are you sure you want to create a copy of the plan ?`,
  versionRestoredSuccess: `Version restored successfully.`,
  phoneNoValidation: `Phone number is a numeric field and should be 10 digit.`,
  companyNameValidation: `Company name is alphanumeric and cannot be more then 250 characters`,
  CompanyAddressValidation: `Company Address cannot me more then 250 characters`,
  urlValidation: `Please enter valid format of the website(www.online.com)`,
  userNameValidation: `Name should be alphabetic with min 3 alphabets and max 250 alphabets.`,
  planNameValidation: `Plan name is alphanumeric with min 4 alphabets and 250 alphabets.`,
  contentCategoryRequired: `Content category is mandatory.`,
  budgetValidation: `Budget value is numeric.`,
  ztvValidation: `Zip select text should be comma separated valid zipcodes with min 1 and 
  max 50 zipcodes.`,
};
