/* eslint-disable @typescript-eslint/no-explicit-any */
import {Component} from '@angular/core';
import {ICellRenderer} from '@simplifi/shared/modules';
import {ICellRendererAngularComp} from 'ag-grid-angular';
@Component({
  selector: 'simplifi-plan-target-cell-renderer',
  templateUrl: './user-name-column-cell-renderer.component.html',
  styleUrls: ['./user-name-column-cell-renderer.component.scss'],
})
export class UserNameColumnCellRendererComponent
  implements ICellRendererAngularComp
{
  constructor() {}
  icons: string[] = [];
  public params!: ICellRenderer;
  public name!: string;
  userIcon = '/assets/images/header/user.svg';
  agInit(params: ICellRenderer): void {
    this.params = params;
    this.name = String(params.data['firstName']);
  }
  refresh() {
    return true;
  }
}
