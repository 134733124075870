import {ApiService, DelAPICommand, IAdapter} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';
export class DeleteUserBulkCommand<T> extends DelAPICommand<T> {
  constructor(apiService: ApiService, adapter: IAdapter<T>, tenantId: string) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.tenantUserFacade}/tenants/${tenantId}/users/bulk`,
    );
  }
}
