import {Subscriber, throwError} from 'rxjs';
import {IAdapter} from '../adapters/i-adapter';
import {IApiService} from '../i-api.service';
import {ICommand} from './i-command';
import {PartialJsonParserWorker} from '@simplifi/main/planner/classes';
import {HttpObserve} from '../types';
import {HttpParams} from '@angular/common/http';
import {STATUS_CODE, UserSessionStoreService} from '../..';
import {AuthService} from '@simplifi/core/auth';

export abstract class GetChunkAPICommand<T extends Partial<R>, R = T>
  implements ICommand
{
  constructor(
    protected readonly apiService: IApiService,
    protected readonly adapter: IAdapter<T, R>,
    protected readonly uri: string,
    protected readonly subscriber: Subscriber<T>,
    protected readonly store: UserSessionStoreService,
    protected readonly authService: AuthService,
  ) {}

  parameters!: {
    data: any;
    headers?: any;
    observe?: HttpObserve;
    query?: HttpParams;
    reportProgress?: boolean;
  };

  execute(): Promise<any> {
    if (!this.parameters) {
      throwError(() => new Error(`Parameters missing for POST ${this.uri}`));
    }
    const parser = new PartialJsonParserWorker(this.subscriber);
    const headers = {
      ...this.parameters?.headers,
      Authorization: `Bearer ${this.store.getAccessToken()}`,
    };

    return this.apiService
      .fetch(this.uri, headers, 'GET')
      .then(response => {
        if (!response.body) {
          throw new Error('ReadableStream not supported by the browser.');
        }
        if (response.status === STATUS_CODE.UNAUTHORIZED) {
          // Token expired, refresh token and retry
          this.authService.refreshToken().subscribe({
            complete: () => {
              this.parameters.headers.Authorization = `Bearer ${this.store.getAccessToken()}`;
              this.execute().catch(err => this.subscriber.error(err));
            },
            error: err => this.subscriber.error(err),
          });
        } else {
          /* The code snippet `const reader = response.body.getReader();` is creating a
          `ReadableStreamDefaultReader` object named `reader` by calling the `getReader()` method on
          the `response.body` property. This reader is used to read data from a stream of
          `Uint8Array` chunks. */
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          this.readStream(reader, decoder, parser).catch(error =>
            this.subscriber.error(error),
          );
        }
      })
      .catch(error => {
        this.subscriber.error(error);
      });
  }

  /**
   * The readStream function reads data from a ReadableStream, decodes it using a TextDecoder, and
   * parses it using a PartialJsonParserWorker.
   * @param reader - The `reader` parameter is a ReadableStreamDefaultReader that reads data from a
   * stream of Uint8Array chunks.
   * @param {TextDecoder} decoder - The `decoder` parameter in the `readStream` function is of type
   * `TextDecoder`. It is used to decode the incoming chunks of data from the `reader` stream into text
   * using the specified encoding.
   * @param {PartialJsonParserWorker} parser - The `parser` parameter is an instance of the
   * `PartialJsonParserWorker` class, which is used to parse and process JSON data in chunks as it is
   * being read from a stream.
   */
  private async readStream(
    reader: ReadableStreamDefaultReader<Uint8Array>,
    decoder: TextDecoder,
    parser: PartialJsonParserWorker,
  ) {
    while (true) {
      const {done, value} = await reader.read();
      if (done) {
        parser.complete();
        break;
      }
      const chunk = decoder.decode(value, {stream: true});
      parser.parseChunk(chunk);
    }
  }
}
