<div
  class="ng-select-wrapper el-form-item"
  [ngClass]="{'horizontal-field': horizontalField}"
>
  <label *ngIf="inputLabel" for="category" class="el-form-item__label">
    {{ label }}
  </label>
  <div class="el-form-item__content">
    <div class="el-input">
      <ng-select
        [items]="items"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        groupBy="selectedAllGroup"
        [selectableGroup]="true"
        [placeholder]="placeholder"
        [multiple]="multiple"
        [required]="required"
        [disabled]="disabled"
        [readonly]="readonly"
        name="{{ name || 'name' }}"
        [id]="id"
        [(ngModel)]="selectModel"
        (ngModelChange)="onChange($event)"
        [searchable]="searchable"
      >
        <ng-template *ngIf="optionsWithIcons" ng-option-tmp let-item="item">
          <div class="option-item flex items-center gap-x-2">
            <ng-container *ngIf="item.srcPath; else iconOptions">
              <span class="option-icon">
                <img [src]="item.srcPath" alt="" />
              </span>
              <div>{{ item.name }}</div>
            </ng-container>
            <ng-template #iconOptions>
              <span class="option-icon">
                <i [class]="item.iconClass"> </i>
              </span>
              <div>{{ item.name }}</div>
            </ng-template>
          </div>
        </ng-template>
      </ng-select>
      <div
        *ngIf="
          form?.form?.controls?.[name]?.invalid &&
          form.form.controls[name]?.errors?.['required'] &&
          form.form.controls[name]?.touched
        "
        class="el-form-item__error"
      >
        {{ validationMessage }}
      </div>
    </div>
  </div>
</div>
