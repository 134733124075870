import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {UserDto} from '../models';
import moment from 'moment';
@Injectable()
export class GetUsersAdapter implements IAdapter<UserDto> {
  /**
   * The function `adaptToModel` takes a response object and converts it into a UserDto[] object if the
   * response has an id property, otherwise it returns the response as is.
   * @param {any} resp - The `resp` parameter is of type `any`, which means it can be any type of object.
   * It is expected to have properties  such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptToModel` returns an instance of the `UserDto[]` class if the `resp` object
   * has an `id` property. Otherwise, it returns the `resp` object itself.
   */
  adaptToModel(resp: any): UserDto {
    // NOSONAR
    const responseData = new UserDto();
    responseData.firstName = resp.firstName;
    responseData.email = resp.email;
    responseData.id = resp?.id;
    responseData.firstName = resp?.firstName;
    responseData.lastLogin = resp.lastLogin
      ? moment(resp.lastLogin).format('MM/DD/YYYY')
      : 'N/A';
    responseData.createdOn = resp.createdOn
      ? moment(resp.createdOn).format('MM/DD/YYYY')
      : 'N/A';

    return responseData;
  }
  /**
   * The function `adaptFromModel` takes a `UserDto[]` object and returns a modified version of it with
   * specific properties and values.
   * @param {UserDto} data - The `data` parameter is an object of type `UserDto[]` which contains various
   * properties such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptFromModel` returns an object with the following properties:
   */
  adaptFromModel(data: UserDto): any {
    return data;
  }
}
