export class UserDto {
  id!: string;
  name!: string;
  firstName!: string;
  email!: string;
  phone?: string;
  lastLogin?: string;
  createdOn?: string;
}

export class UserCount {
  count!: number;
}
