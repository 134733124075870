<div class="switch-main el-row--flex is-middle gap-x-2">
  <!-- // sonarignore:start -->
  <label class="switch" for="radio-{{ params.data.id }}">
    <!-- // sonarignore:end -->
    <input
      id="radio-{{ params.data.id }}"
      type="radio"
      name="switch-{{ params.data.id }}"
      [checked]="isToggleSelected"
      (click)="onSwitchClick()"
      [disabled]="isToggleSelected"
    />
    <span class="slider"></span>
  </label>
</div>
