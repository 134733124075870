import {Injectable} from '@angular/core';
import {UserDto} from '../models';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UserProfileAdapterService {
  /**
   * The function `adaptToModel` takes a response object and converts it into a UserDto object if the
   * response has an id property, otherwise it returns the response as is.
   * @param {UserDto} resp - The `data` parameter is an object of type `UserDto` which contains various
      lastLogin: data.lastLogin,
   * @returns The function `adaptToModel` returns an instance of the `UserDto` class if the `resp` object
   * has an `id` property. Otherwise, it returns the `resp` object itself.
   */
  adaptToModel(resp: any): UserDto {
    if (resp.id) {
      const user = new UserDto();
      user.name = resp.firstName;
      user.id = resp.id;
      user.email = resp.email;
      user.phone = resp.phone;
      let lastLogin = resp.lastLogin;
      const today = new Date();
      user.lastLogin = lastLogin
        ? moment(lastLogin).format('MM/DD/YYYY')
        : moment(today).format('MM/DD/YYYY');
      return user;
    }
    return resp;
  }

  /**
   * The function `adaptFromModel` takes a `UserDto` object and returns a modified version of it with
   * specific properties and values.
   * @param {UserDto} data - The `data` parameter is an object of type `UserDto` which contains various
      lastLogin: data.lastLogin,
   * properties such as `firstName`,  `email`, `phone`, `lastLogin`
   * @returns The function `adaptFromModel` returns an object with the following properties:
   */
  adaptFromModel(data: UserDto): any {
    return {
      firstName: data.name,
      phone: data.phone,
    };
  }
}
