
export const environment = {
  production: false,
  homePath: '/main/planner',
  loginPath: '/login',
  baseApiUrl: 'https://simplifigcp-api.filelogix.net/',
  clientId: 'superadmin',
  clientSecret: 'saqw21!@',
  cryptoSecretKey: 'simplifi-plantool-hopls',
  simplifiLogo: '/assets/ico/simplifi-iplanner-logo.svg',
  testPassword: 'test',
  messageTimeout: 5000,
  authServiceUrl: 'auth-service',
  tenantUserFacade: 'tenant-user-facade',
  chatFacadeApiUrl: 'chat-facade',
  authClientId: 1,
  planFacadeUrl: 'plan-facade',
  recaptchaKey: '6Le6frUpAAAAAHpmRELm-SpLUGZId-4ZrtuUTIge',
  agGridLicence: 'undefined',
  fsOrgId: 'undefined',
  fsDevMode: undefined,
  fsDebug: undefined,
  fsNamespace: 'undefined',
};