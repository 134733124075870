import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  AfterViewInit,
} from '@angular/core';

@Directive({
  selector: '[simplifiStickyDrawer]',
})
export class StickyDrawerDirective implements AfterViewInit {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkSticky();
    });
    const mainContainer = document.querySelector(
      '.main-container',
    ) as HTMLElement;
    if (mainContainer) {
      mainContainer.addEventListener('scroll', () => this.checkSticky());
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkSticky();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.checkSticky();
  }

  checkSticky() {
    const mainContainer = document.querySelector(
      '.main-container',
    ) as HTMLElement;
    const drawer = this.elementRef.nativeElement as HTMLElement;
    const breadcrumbContainer = document.querySelector(
      '.breadcrumb-container',
    ) as HTMLElement;

    if (!mainContainer || !drawer || !breadcrumbContainer) return;

    const mainContainerRect = mainContainer.getBoundingClientRect();
    const breadcrumbContainerRect = breadcrumbContainer.getBoundingClientRect();

    const drawerInitialTop = breadcrumbContainerRect.bottom;
    const rightOffset =
      breadcrumbContainerRect.left -
      mainContainerRect.left -
      mainContainer.offsetLeft +
      16;

    this.renderer.setStyle(drawer, 'position', 'fixed');
    this.renderer.setStyle(drawer, 'top', `${drawerInitialTop}px`);
    this.renderer.setStyle(drawer, 'right', `${rightOffset}px`);
  }
}
