import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'simplifi-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent {
  constructor(private router: Router) {}

  navigatePlanner() {
    this.router.navigate([`/main/planner`]);
    return false;
  }
}
