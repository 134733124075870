import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {Organization} from '../models';
import moment from 'moment';
export interface TenantListResponse {
  tenantList: Organization[];
  tenantListCount: number;
}
@Injectable()
export class DescendantOrganizationAdapter
  implements IAdapter<TenantListResponse>
{
  /**
   * The function `adaptToModel` takes a response object and converts it into a Organization object if the
   * response has an id property, otherwise it returns the response as is.
   * @param {any} resp - The `resp` parameter is of type `any`, which means it can be any type of object.
   * It is expected to have properties  such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptToModel` returns an instance of the `Organization` class if the `resp` object
   * has an `id` property. Otherwise, it returns the `resp` object itself.
   */
  adaptToModel(resp: any): TenantListResponse {
    const respArray: Organization[] = [];
    if (resp?.tenantList?.length) {
      resp?.tenantList?.forEach((tenant: any) /*NOSONAR*/ => {
        const responseData = new Organization();
        responseData.id = tenant?.id;
        responseData.name = tenant?.name;
        responseData.pname = tenant?.pname;
        responseData.address = tenant?.address;
        responseData.createdOn = tenant.createdOn;
        responseData.key = tenant.key;
        responseData.status = tenant.status;
        responseData.website = tenant.website;
        responseData.parentOrgId = tenant.parentOrgId ?? 'N/A';
        responseData.createdOn = tenant.createdOn
          ? moment(tenant.createdOn).format('MM/DD/YYYY')
          : '';
        respArray.push(responseData);
      });
    }
    return {tenantList: respArray, tenantListCount: resp?.tenantListCount};
  }
  /**
   * The function `adaptFromModel` takes a `Organization` object and returns a modified version of it with
   * specific properties and values.
   * @param {Organization} data - The `data` parameter is an object of type `Organization` which contains various
   * properties such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptFromModel` returns an object with the following properties:
   */
  adaptFromModel(data: TenantListResponse): any {
    return data;
  }
}
