import {Injectable} from '@angular/core';
import {UserDto} from '../models';
import moment from 'moment';

@Injectable()
export class InviteUserAdapterService {
  /**
   * The function `adaptToModel` takes a response object and converts it into a UserDto object if the
   * response has an id property, otherwise it returns the response as is.
   * @param {UserDto} resp - The `data` parameter is an object of type `UserDto` which contains various
      lastLogin: data.lastLogin,
   * @returns The function `adaptToModel` returns an instance of the `UserDto` class if the `resp` object
   * has an `id` property. Otherwise, it returns the `resp` object itself.
   */
  adaptToModel(response: any): UserDto[] {
    const invitedUsers: UserDto[] = [];
    if (response.length > 0) {
      response.forEach(
        (resp: {name: string; userId: string; email: string}) => {
          const user = new UserDto();
          user.firstName = resp.name;
          user.id = resp.userId;
          user.email = resp.email;
          user.lastLogin = 'N/A';
          const today = new Date();
          user.createdOn = moment(today).format('MM/DD/YYYY');
          invitedUsers.push(user);
        },
      );
    }
    return invitedUsers;
  }

  /**
   * The function `adaptFromModel` takes a `UserDto` object and returns a modified version of it with
   * specific properties and values.
   * @param {UserDto} data - The `data` parameter is an object of type `UserDto` which contains various
      lastLogin: data.lastLogin,
   * properties such as `firstName`,  `email`, `phone`, `lastLogin`
   * @returns The function `adaptFromModel` returns an object with the following properties:
   */
  adaptFromModel(data: UserDto[]): any {
    return data;
  }
}
