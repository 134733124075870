import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[simplifiPreventInputkey]',
  standalone: true,
})
export class PreventInputkeyDirective {
  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }
}
