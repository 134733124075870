import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {IToaster} from './i-toaster';
import {ToasterConfig} from './types';
import {IAnyObject} from '../i-any-object';

@Injectable({
  providedIn: null,
})
export class ToasterService implements IToaster {
  constructor(private readonly toastrSvc: ToastrService) {}

  show(
    message: string,
    title?: string,
    config?: Partial<ToasterConfig>,
  ): IAnyObject {
    return this.toastrSvc.show(message, title, config);
  }

  success(
    message: string,
    title?: string,
    config?: Partial<ToasterConfig>,
  ): IAnyObject {
    return this.toastrSvc.success(message, title, config);
  }

  info(
    message: string,
    title?: string,
    config?: Partial<ToasterConfig>,
  ): IAnyObject {
    return this.toastrSvc.info(message, title, config);
  }

  warn(
    message: string,
    title?: string,
    config?: Partial<ToasterConfig>,
  ): IAnyObject {
    return this.toastrSvc.warning(message, title, config);
  }

  error(
    message: string,
    title?: string,
    config?: Partial<ToasterConfig>,
  ): IAnyObject {
    return this.toastrSvc.error(message, title, config);
  }
}
