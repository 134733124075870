import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'simplifi-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
})
export class ConfirmationDialogComponent {
  isDisabled = false;
  confirmAction = false;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      confirmationMessage: string;
      actionNoLabel: string;
      actionYesLabel: string;
      deleteCount: boolean;
      totalRecord: string;
      confirmationTitle: string;
      showCheckbox: boolean;
      checkBoxText: string;
      isDeleteButtonDisabled: boolean;
      recordText: string;
      hideConfirmButton: boolean;
    },
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
  ) {
    this.isDisabled = data.isDeleteButtonDisabled;
  }
  onClick(value: boolean) {
    this.dialogRef.close(value);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmDelete(event: any) /* NOSONAR */ {
    this.isDisabled = !event.checked;
  }
}
