import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectBoxDropdown} from '@simplifi/shared/interfaces';
const INTEGER_FIVE = 5;

@Component({
  selector: 'simplifi-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() currentPage = 1;
  totalPages = 10;
  recordsPerPage = 10;
  @Input() totalRecords = 1000;
  @Output() selectedPage = new EventEmitter<number>();
  @Output() recordsPerPageSelection = new EventEmitter<number>();
  set currentPageNumber(page) {
    this.currentPage = page;
  }
  @Input()
  get currentPageNumber(): number {
    return this.currentPage;
  }
  selected = 10;
  paginationData: SelectBoxDropdown[] = [
    {value: 20, name: '20/page'},
    {value: 50, name: '50/page'},
    {value: 100, name: '100/page'},
  ];

  paginationValues: SelectBoxDropdown[] = [{value: 10, name: '10/page'}];

  ngOnInit(): void {
    this.paginationData.forEach(paginationValue => {
      if (paginationValue.value <= this.totalRecords) {
        this.paginationValues.push(paginationValue);
      }
    });
  }

  getPageNumbers(): number[] {
    const pageNumbers = [];
    this.totalPages = Math.ceil(this.totalRecords / this.recordsPerPage);
    // show all pages if there are less than or equal to 5 pages
    if (this.totalPages <= INTEGER_FIVE) {
      for (let i = 1; i <= this.totalPages; i++) {
        pageNumbers.push(i);
      }
    }
    // show ellipsis if there are more than 5 pages
    else {
      const start = Math.max(1, this.currentPage - 1);
      const end = Math.min(this.totalPages, this.currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (start > 1) {
        pageNumbers.unshift(-1);
        pageNumbers.unshift(1);
      }

      if (end < this.totalPages) {
        pageNumbers.push(-1);
        pageNumbers.push(this.totalPages);
      }
    }

    return pageNumbers;
  }

  onPageSelect(page: number) {
    this.currentPage = page;
    this.selectedPage.emit(page);
  }

  onRecordsPerPageSelectionChange(value: number) {
    this.currentPage = 1;
    this.recordsPerPageSelection.emit(value);
  }
}
