import {ApiService, GetAPICommand, IAdapter} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';
export class GetUsersCountCommand<T> extends GetAPICommand<T> {
  constructor(apiService: ApiService, adapter: IAdapter<T>, tenantId: string) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.tenantUserFacade}/tenants/${tenantId}/users/count`,
    );
  }
}
