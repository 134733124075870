import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AnyAdapter, ApiService} from '@simplifi/core/api';
import {
  AuthTokenSkipHeader,
  CaptchaTokenHeader,
} from '@simplifi/core/constants';
import {Observable} from 'rxjs';
import {Tenant} from '../models/tenant.model';
import {
  TenantRegisterCommand,
  TenantInviteVerifyCommand,
  TenantAcceptInviteCommand,
} from '../commands';
import {User} from '@simplifi/core/auth';
import {TenantDto} from '../models';

@Injectable()
export class RegisterService {
  private readonly authHeaders = new HttpHeaders().set(AuthTokenSkipHeader, '');
  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter,
  ) {}

  registerTenant(data: Tenant, user: User): Observable<TenantDto> {
    const command: TenantRegisterCommand<TenantDto> = new TenantRegisterCommand(
      this.apiService,
      this.anyAdapter,
    );
    command.parameters = {
      data: {...data, email: user.email, phone: user.phone},
      headers: new HttpHeaders()
        .set(AuthTokenSkipHeader, '')
        .set(CaptchaTokenHeader, ''),
    };
    return command.execute();
  }

  public tenantInviteVerify(
    acceptInviteCode: string,
  ): Observable<{userExist: boolean; inviteToken: string}> {
    const command: TenantInviteVerifyCommand<{
      userExist: boolean;
      inviteToken: string;
    }> = new TenantInviteVerifyCommand(this.apiService, this.anyAdapter);

    let params = new HttpParams();
    params = params.set('code', acceptInviteCode);
    command.parameters = {
      query: params,
      headers: this.authHeaders,
    };
    return command.execute();
  }

  tenantAcceptInvite(
    acceptInviteCode: string,
    password?: string,
  ): Observable<{code: string; pwd: string; message?: string}> {
    const command: TenantAcceptInviteCommand<{code: string; pwd: string}> =
      new TenantAcceptInviteCommand(this.apiService, this.anyAdapter);

    let authHeaders = new HttpHeaders();
    authHeaders = authHeaders.set(AuthTokenSkipHeader, '');
    command.parameters = {
      data: {
        code: acceptInviteCode,
        pwd: btoa(password ?? ''),
      },
      headers: authHeaders,
    };
    return command.execute();
  }
}
