import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'simplifi-plan-version-creation-dialog',
  templateUrl: './plan-version-creation-dialog.component.html',
  styleUrl: './plan-version-creation-dialog.component.scss',
})
export class PlanVersionCreationDialogComponent {
  versionTag = '';
  constructor(
    public dialogRef: MatDialogRef<PlanVersionCreationDialogComponent>,
  ) {}
  onClick(value: boolean) {
    this.dialogRef.close({
      isSaved: value,
      versionTag: this.versionTag,
    });
  }
}
