import {Inject, Injectable} from '@angular/core';
import {InMemoryStorageService, StorageService} from 'ngx-webstorage-service';

import {StoreKeys} from './store-keys.enum';
import {EncryptDecrypt} from './encrypt-data';
import {APPLICATION_STORE, APP_SESSION_STORE} from './keys';
import {User} from '../auth/models/user.model';
import {StoreModule} from './store.module';

@Injectable({
  providedIn: StoreModule,
})
export class UserSessionStoreService extends EncryptDecrypt {
  constructor(
    @Inject(APPLICATION_STORE)
    private readonly localStore: StorageService,
    @Inject(APP_SESSION_STORE)
    private readonly sessionStore: StorageService,
    private readonly inMemoryStore: InMemoryStorageService,
  ) {
    super();
  }

  public saveAccessToken(token: string): boolean {
    this.localStore.set(StoreKeys.ACCESS_TOKEN_KEY, this.encrypt(token));
    return true;
  }

  public getAccessToken(): string {
    return this.decrypt(this.localStore.get(StoreKeys.ACCESS_TOKEN_KEY));
  }

  public removeAccessToken(): boolean {
    this.localStore.remove(StoreKeys.ACCESS_TOKEN_KEY);
    return true;
  }

  public saveRefreshToken(token: string): boolean {
    this.localStore.set(StoreKeys.REFRESH_TOKEN_KEY, this.encrypt(token));
    return true;
  }

  public getRefreshToken(): string {
    return this.decrypt(this.localStore.get(StoreKeys.REFRESH_TOKEN_KEY));
  }

  public removeRefreshToken(): boolean {
    this.localStore.remove(StoreKeys.REFRESH_TOKEN_KEY);
    return true;
  }

  public saveRememberMeData(cred: {
    userName: string;
    password: string;
  }): boolean {
    this.localStore.set(StoreKeys.REMEMBER_ME_DATA, this.encrypt(cred));
    return true;
  }

  public getRememberMeData(): {userName: string; password: string} {
    return this.decrypt(this.localStore.get(StoreKeys.REMEMBER_ME_DATA));
  }

  public setUser(user: User): void {
    this.inMemoryStore.set(StoreKeys.USER_KEY, user);
  }

  public getUser(): User {
    return this.inMemoryStore.get(StoreKeys.USER_KEY);
  }

  public setPubnubAskChannel(channels: string): void {
    return this.localStore.set(StoreKeys.ASK_CHANNEL, this.encrypt(channels));
  }

  public getPubnubAskChannel(): string {
    return this.decrypt(this.localStore.get(StoreKeys.ASK_CHANNEL));
  }

  public setPubnubKeys(keys: {subscribe: string; token: string}) {
    this.localStore.set(StoreKeys.PUBNUB_KEYS, this.encrypt(keys));
  }

  public getPubnubKeys(): {subscribe: string; token: string} {
    return this.decrypt(this.localStore.get(StoreKeys.PUBNUB_KEYS));
  }

  public setSessionTimeout(data: boolean) {
    this.sessionStore.set(StoreKeys.SESSION_TIMEOUT, data);
  }

  public getSessionTimeout(): boolean {
    return this.sessionStore.get(StoreKeys.SESSION_TIMEOUT);
  }

  public removeSessionTimeout(): boolean {
    this.sessionStore.remove(StoreKeys.SESSION_TIMEOUT);
    return true;
  }

  public setlastActivity(time: Date): void {
    this.localStore.set(StoreKeys.LAST_ACTIVITY, time);
  }
  public getlastActivity(): Date {
    return this.localStore.get(StoreKeys.LAST_ACTIVITY);
  }
  public removelastActivity() {
    this.localStore.remove(StoreKeys.LAST_ACTIVITY);
  }

  public setMediaMixAskChannel(channel: string): void {
    return this.localStore.set(
      StoreKeys.MEDIA_MIX_ASK_CHANNEL,
      this.encrypt(channel),
    );
  }

  public getMediaMixAskChannel(): string {
    return this.decrypt(this.localStore.get(StoreKeys.MEDIA_MIX_ASK_CHANNEL));
  }

  public saveLastAccessedUrl(url: string): void {
    this.sessionStore.set(StoreKeys.LAST_ACCESSED_URL, url);
  }

  public getLastAccessedUrl(): string {
    return this.sessionStore.get(StoreKeys.LAST_ACCESSED_URL);
  }

  public saveTokenExpiry(expires: number): boolean {
    this.inMemoryStore.set(StoreKeys.TOKEN_EXPIRY, expires);
    return true;
  }

  public getTokenExpiry(): number {
    return this.inMemoryStore.get(StoreKeys.TOKEN_EXPIRY);
  }

  public saveStateSession(id: string): boolean {
    this.inMemoryStore.set(StoreKeys.STATE_SESSION_ID, id);
    return true;
  }

  public getStateSession(): string {
    return this.inMemoryStore.get(StoreKeys.STATE_SESSION_ID);
  }

  public removeStateSession(): void {
    return this.inMemoryStore.remove(StoreKeys.STATE_SESSION_ID);
  }

  public clearAll(): void {
    const remembermeData = this.getRememberMeData();
    this.inMemoryStore.clear();
    this.sessionStore.clear();
    this.localStore.clear();
    this.saveRememberMeData(remembermeData);
  }
}
