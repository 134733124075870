<div class="input-chips flex flex-col">
  <mat-label *ngIf="label" class="el-form-item__label">{{ label }}</mat-label>
  <mat-form-field class="simpli-chip-list" appearance="outline">
    <mat-chip-grid #chipGrid aria-label="Enter items">
      @for (item of items; track item) {
        <mat-chip-row
          (removed)="remove(item)"
          [editable]="true"
          (edited)="edit(item, $event)"
          [aria-description]="'press enter to edit ' + item.name"
        >
          {{ item.name }}
          <button matChipRemove [attr.aria-label]="'remove ' + item.name">
            <i class="el-icon-close-small"></i>
          </button>
        </mat-chip-row>
      }
      <input
        placeholder="Emails"
        name="emails"
        type="email"
        [(ngModel)]="inputModel"
        name="{{ name || 'name' }}"
        [id]="id"
        [pattern]="pattern"
        [required]="required"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
        (ngModelChange)="onChange($event)"
        maxlength="{{ maxlength || '250' }}"
      />
    </mat-chip-grid>
    <mat-hint *ngIf="hintText">{{ hintText }}</mat-hint>
  </mat-form-field>
  <div *ngIf="errorMessage" class="el-form-item__error">
    {{ errorMessage }}
  </div>
</div>
