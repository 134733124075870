import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'simplifi-delete-confirmation-dialog',
  templateUrl: './switch-confirmation-dialog.component.html',
  styleUrl: './switch-confirmation-dialog.component.scss',
})
export class SwitchConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {confirmationMessage: string},
    public dialogRef: MatDialogRef<SwitchConfirmationDialogComponent>,
  ) {}
  onClick(value: boolean) {
    this.dialogRef.close(value);
  }
}
