import {Injectable} from '@angular/core';
import {User} from '@simplifi/core/auth';
import {Observable, Subject, of} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserProfileDataService {
  private readonly userInfo = new Subject<User>();

  userInfoObv = this.userInfo.asObservable();

  setData(userInfo: User): Observable<User> {
    this.userInfo.next(userInfo);
    return of(userInfo);
  }
}
