import {Injectable} from '@angular/core';
import {UserDto} from '@simplifi/main/profile/models';
import {Subject} from 'rxjs';

@Injectable()
export class PageSettingsService {
  private readonly titleSubject = new Subject<string>();
  pageTitle = this.titleSubject.asObservable();
  public readonly inviteUserResponse = new Subject<UserDto[]>();
  public sendPageRoute(title: string) {
    this.titleSubject.next(title);
  }

  public setInvitedUserdData(invitedUsers: UserDto[]) {
    this.inviteUserResponse.next(invitedUsers);
  }
}
