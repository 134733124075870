import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {UserDto} from '../models';
import moment from 'moment';
export interface UserListResponse {
  userList: UserDto[];
  userListCount: number;
}
@Injectable()
export class UserListAdapter implements IAdapter<UserListResponse> {
  /**
   * The function `adaptToModel` takes a response object and converts it into a UserListResponse object if the
   * response has an id property, otherwise it returns the response as is.
   * @param {any} resp - The `resp` parameter is of type `any`, which means it can be any type of object.
   * It is expected to have properties  such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptToModel` returns an instance of the `UserListResponse` class if the `resp` object
   * has an `id` property. Otherwise, it returns the `resp` object itself.
   */
  adaptToModel(resp: any): UserListResponse {
    const respArray: UserDto[] = [];
    if (resp?.userList?.length) {
      resp?.userList?.forEach((user: UserDto) => {
        const responseData = new UserDto();
        responseData.id = user?.id;
        responseData.firstName = user?.firstName;
        responseData.lastLogin = user.lastLogin
          ? moment(user.lastLogin).format('MM/DD/YYYY')
          : '';
        responseData.createdOn = user.createdOn
          ? moment(user.createdOn).format('MM/DD/YYYY')
          : '';
        respArray.push(responseData);
      });
    }
    return {userList: respArray, userListCount: resp?.userListCount};
  }
  /**
   * The function `adaptFromModel` takes a `UserListResponse` object and returns a modified version of it with
   * specific properties and values.
   * @param {UserListResponse} data - The `data` parameter is an object of type `UserListResponse` which contains various
   * properties such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptFromModel` returns an object with the following properties:
   */
  adaptFromModel(data: UserListResponse): any {
    return data;
  }
}
