import {ApiService, IAdapter, PatchAPICommand} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';
export class UpdateOrganizationInformation<T> extends PatchAPICommand<T> {
  constructor(apiService: ApiService, adapter: IAdapter<T>, tenantId: string) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.tenantUserFacade}/tenants/${tenantId}`,
    );
  }
}
