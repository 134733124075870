import {ApiService, IAdapter, PostAPICommand} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';
export class AddOrganization<T> extends PostAPICommand<T> {
  constructor(apiService: ApiService, adapter: IAdapter<T>) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.tenantUserFacade}/tenants`,
    );
  }
}
