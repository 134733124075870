<div class="switch-main el-row--flex is-middle gap-x-2">
  <!-- // sonarignore:start -->
  <label class="switch" for="radio-{{ id }}">
    <!-- // sonarignore:end -->
    <input
      id="radio-{{ id }}"
      type="radio"
      name="switch-{{ id }}"
      [checked]="isChecked"
      [disabled]="isDisabled"
      (click)="toggleSwitch()"
    />
    <span class="slider"></span>
  </label>
  <span *ngIf="label" for="switch" class="text"> {{ label }}</span>
</div>
