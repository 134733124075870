import {Component, Input} from '@angular/core';

export interface Task {
  name: string;
  completed: boolean;
  subtasks?: Task[];
}
@Component({
  selector: 'simplifi-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrl: './page-loader.component.scss',
})
export class PageLoaderComponent {
  @Input() diameter = 100;
  @Input() color = 'black';
}
