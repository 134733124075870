import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {Organization} from '../models';
import moment from 'moment';

@Injectable()
export class OrganizationAdapter implements IAdapter<Organization> {
  /**
   * The function `adaptToModel` takes a response object and converts it into a Organization object if the
   * response has an id property, otherwise it returns the response as is.
   * @param {any} resp - The `resp` parameter is of type `any`, which means it can be any type of object.
   * It is expected to have properties  such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptToModel` returns an instance of the `Organization` class if the `resp` object
   * has an `id` property. Otherwise, it returns the `resp` object itself.
   */
  adaptToModel(resp: any): Organization {
    if (resp.id) {
      const organization = new Organization();
      organization.address = resp.address;
      organization.id = resp.id;
      organization.name = resp.name;
      organization.website = resp.website;
      organization.parentOrgId = resp.parentOrgId;
      organization.createdOn = resp.createdOn
        ? moment(resp.createdOn).format('MM/DD/YYYY')
        : '';
      return organization;
    }
    return resp;
  }

  /**
   * The function `adaptFromModel` takes a `Organization` object and returns a modified version of it with
   * specific properties and values.
   * @param {Organization} data - The `data` parameter is an object of type `Organization` which contains various
   * properties such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptFromModel` returns an object with the following properties:
   */
  adaptFromModel(data: Organization): any {
    return {
      name: data.name,
      key: data.key,
      address: data.address,
      status: data.status,
      website: data.website ?? '',
      parentOrgId: data.parentOrgId,
      parentTenantId: data.parentTenantId,
    };
  }
}
