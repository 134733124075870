import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {MatChipsModule} from '@angular/material/chips';

import {
  InputBoxComponent,
  InputButtonComponent,
  HeaderComponent,
  BreadcrumbComponent,
  DateRangeComponent,
  PageLoaderComponent,
  SwitchComponent,
  InputChipsComponent,
} from '../shared/components';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {
  CustomTooltipDirective,
  NumberDirective,
  TrimDirective,
  PreventInputkeyDirective,
  StickyDrawerDirective,
} from '@simplifi/shared/directives';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';

const components = [
  InputButtonComponent,
  InputBoxComponent,
  DateRangeComponent,
  HeaderComponent,
  BreadcrumbComponent,
  PageLoaderComponent,
  SwitchComponent,
  InputChipsComponent,
];
const matModules = [
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  OverlayModule,
  MatMenuModule,
  MatBadgeModule,
  MatIconModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatListModule,
  MatCheckboxModule,
  MatSidenavModule,
  CdkAccordionModule,
  MatDialogModule,
  MatTabsModule,
  MatChipsModule,
  MatFormFieldModule,
];
@NgModule({
  declarations: [...components, CustomTooltipDirective, StickyDrawerDirective],
  imports: [
    ...matModules,
    NgxDaterangepickerMd.forRoot(),
    CommonModule,
    FormsModule,
    NgSelectModule,
    TrimDirective,
    NumberDirective,
    RouterModule,
    PreventInputkeyDirective,
  ],
  exports: [
    ...components,
    ...matModules,
    FormsModule,
    NgSelectModule,
    NgxDaterangepickerMd,
    CustomTooltipDirective,
    StickyDrawerDirective,
  ],
})
export class ThemeModule {}
