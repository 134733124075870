<div class="container">
  <div class="confirmation-text">
    <h3 class="els-header__title mt-2">{{ data.confirmationMessage }}</h3>
  </div>
  <div class="action-buttons">
    <simplifi-input-button
      type="basic"
      color="default"
      [name]="'Cancel'"
      (clickButton)="onClick(false)"
    ></simplifi-input-button>
    <simplifi-input-button
      type="basic"
      color="danger"
      [name]="'Switch'"
      (clickButton)="onClick(true)"
    ></simplifi-input-button>
  </div>
</div>
