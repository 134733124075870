import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService, User} from '@simplifi/core/auth';
import {ComponentBaseDirective} from '@simplifi/core/component-base';
import {UserSessionStoreService} from '@simplifi/core/store';
import {environment} from '@simplifi/env/environment';
import {NotificationType} from '@simplifi/shared/enums';
import {PubnubService, UserProfileDataService} from '@simplifi/shared/services';
import Pubnub from 'pubnub';

@Component({
  selector: 'simplifi-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent extends ComponentBaseDirective implements OnInit {
  headerMenuIcon = '/assets/images/header/switch-icon.svg';
  headerLogo = '/assets/images/header/header-logo.svg';
  userIcon = '/assets/images/header/user.svg';
  dspImg = '/assets/images/header/simplifi-dsp.png';
  plannerImg = '/assets/images/header/simplifi-planner.png';
  callAvatar = '/assets/images/header/avatar.png';
  isOpen = false;
  homeUrl = environment.homePath;
  profileUrl = '/main/profile';
  user: User = this.store.getUser();
  pubnubListnerObj!: Pubnub.ListenerParameters;
  constructor(
    private readonly store: UserSessionStoreService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly pubnubService: PubnubService,
    private readonly userProfileDataService: UserProfileDataService,
  ) {
    super();
    this.userProfileDataService.userInfoObv.subscribe(userInfo => {
      const userDetails = userInfo;
      if (this.user && userDetails) {
        this.user = userInfo;
        this.store.setUser(this.user);
      }
    });
  }

  /**
   * The ngOnInit function calls the pubnubListner method when the component is initialized.
   */
  ngOnInit() {
    this.pubnubListner();
  }

  /**
   * The `logout` function logs out the user by unsubscribing from PubNub channels, clearing the store,
   * and navigating to the login page.
   */
  logout() {
    this.authService.logout().subscribe(() => {
      this.pubnubService.unsubscribeAllChannels();
      this.store.clearAll();
      this.router.navigate([environment.loginPath]);
    });
  }

  /**
   * The function `pubnubListner` sets up a listener to handle incoming messages and triggers a logout
   * action if a specific type of system notification is received.
   */
  pubnubListner() {
    this.pubnubListnerObj = {
      message: m => {
        const message = JSON.parse(m.message.description);
        if (
          message.notificationType === NotificationType.SystemNotif &&
          message.action === 'logout'
        ) {
          this.logout();
        }
      },
    };
    this.pubnubService.pubnub?.addListener(this.pubnubListnerObj);
  }

  navigateToProfile() {
    this.router.navigate([this.profileUrl]);
  }
  closeOverlay() {
    this.isOpen = false;
  }
}
