import {Component} from '@angular/core';
import {ICellRenderer} from '@simplifi/shared/modules';
import {DeleteService} from '@simplifi/shared/services/delete.service';
import {ICellRendererAngularComp} from 'ag-grid-angular';
@Component({
  selector: 'simplifi-plan-target-cell-renderer',
  templateUrl: './delete-button-cell-renderer.component.html',
  styleUrls: ['./delete-button-cell-renderer.component.scss'],
})
export class DeleteButtonCellRendererComponent
  implements ICellRendererAngularComp
{
  constructor(private deleteService: DeleteService) {}
  icons: string[] = [];
  public params!: ICellRenderer;
  agInit(params: ICellRenderer): void {
    this.params = params;
  }

  onButtonClick() {
    this.deleteService.setData(this.params.data.id);
  }
  refresh() {
    return true;
  }
}
