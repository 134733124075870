import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToastrModule} from 'ngx-toastr';
import {TOASTER_SERVICE_KEY} from './types';
import {ToasterService} from './toaster.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
  ],
  providers: [
    {
      provide: TOASTER_SERVICE_KEY,
      useClass: ToasterService,
    },
  ],
})
export class ToasterModule {}
