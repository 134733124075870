import {LocationType} from '@simplifi/shared/enums';
import {ContentCategory} from './content-categories.model';
import {Words} from '../interfaces';
import {BudgetMediaType} from './budget-media-type.model';
import {PlanResultView} from '@simplifi/shared/models';
import {IGridColumn} from '@simplifi/shared/modules';
import {PlanResultZtvSummaryData} from './plan-result-ztv-summary-data.model';
export class Plan {
  id!: string;
  currentVersion!: string;
  name?: string;
  status!: string;
  startDate?: Date;
  endDate?: Date;
  advertiserUrl?: string;
  campaignGoalId?: string;
  campaignGoalName?: string;
  budgetType?: string;
  budgetAmount!: number;
  budgetAllocationType = 'percent';
  locationType?: LocationType;
  locations?: string[];
  plansVersionLocation?: PlansVersionLocation;
  contentCategories?: string[];
  contentCategoriesName?: ContentCategory[];
  keyword!: Suggestions;
  ztv!: Suggestions;
  contextual!: Suggestions;
  addressable!: Suggestions;
  hasKeywords!: boolean;
  hasAddressable!: boolean;
  hasContextual!: boolean;
  hasZtv!: boolean;
  totalBudget!: number;
  budgetMediaTypes!: Map<string, BudgetMediaType>;
  chatChannelId!: string;
  versionTag?: string;
  constructor() {
    this.keyword = new Suggestions();
    this.ztv = new Suggestions();
    this.contextual = new Suggestions();
    this.addressable = new Suggestions();
    this.contentCategories = [];
  }
}

export class Suggestions {
  enabled = false;
  words: Words[] = [];
}
export class PlansVersionLocation {
  type!: LocationType;
  data!: object;
}

export class PlansGeneralSectionForm {
  isValid = false;
  isDirty = false;
  checkTargets?: boolean = false;
}

export class PlansResultExport {
  title = '';
  colDefs: IGridColumn[] = [];
  rowData: (PlanResultZtvSummaryData | PlanResultView)[] = [];
}
