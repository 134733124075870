import {Injectable} from '@angular/core';
import {IAdapter} from '../../api/adapters';
import {NameId} from '../../api/models';
import {CoreAuthModule} from '../auth.module';
import {User} from '../models';

@Injectable({
  providedIn: CoreAuthModule,
})
export class LoggedInUserAdapterService implements IAdapter<User> {
  adaptToModel(resp: any): User {
    const user: User = new User();
    if (resp.user) {
      user.id = resp.user.id;
      user.firstName = resp.user.firstName;
      user.username = resp.user.username;
      user.email = resp.user.email;
      user.phone = resp.user.phone;
      user.defaultTenantId = resp.user.defaultTenantId;
      user.permissions = resp.user.permissions;
      user.lastLogin = resp.user.lastLogin;
      user.photo = resp.user.photoUrl;
      user.tenant = new NameId({
        id: resp.user.tenantId,
        name: resp.tenant.name,
        parentName: resp.tenant.pname,
      });
      user.userTenantId = resp.user.userTenantId;
      user.role = resp.user.role;
    }
    // Let it invoke constructor now in order to trigger data validations
    return new User(user);
  }

  adaptFromModel(data: User): any {
    return data;
  }
}
