<div class="w-100">
  <div
    class="grid grid-cols-[1fr_auto_1fr] gap-x-1 items-center has-parent"
    *ngIf="hasParent"
  >
    <span class="truncate name" [matTooltip]="this.pname">{{
      this.pname
    }}</span>
    <mat-icon class="pname-icon">keyboard_arrow_right</mat-icon>
    <span class="truncate name text-left" [matTooltip]="this.name">{{
      this.name
    }}</span>
  </div>
  <span *ngIf="!hasParent">
    {{ this.name }}
  </span>
</div>
