/* eslint-disable @typescript-eslint/no-explicit-any */
import {Component} from '@angular/core';
import {ICellRenderer} from '@simplifi/shared/modules';
import {DeleteService} from '@simplifi/shared/services/delete.service';
import {ICellRendererAngularComp} from 'ag-grid-angular';
@Component({
  selector: 'simplifi-plan-target-cell-renderer',
  templateUrl: './name-with-parent-column-cell-renderer.component.html',
  styleUrls: ['./name-with-parent-column-cell-renderer.component.scss'],
})
export class NameWithParentColumnCellRendererComponent
  implements ICellRendererAngularComp
{
  constructor(private deleteService: DeleteService) {}
  icons: string[] = [];
  public params!: ICellRenderer;
  public name!: string;
  public pname!: string;
  public nameCellString!: string;
  public hasParent = false;
  agInit(params: ICellRenderer): void {
    this.params = params;
    this.name = params.data.name;
    this.pname = params.data.pname;
    this.hasParent = params.data.pname?.length > 0;
  }

  refresh() {
    return true;
  }
}
