import {IAnyObject} from '@simplifi/core/i-any-object';
import {Subscriber} from 'rxjs';

export class PartialJsonParserWorker {
  private worker: Worker;

  /**
   * The constructor initializes a Worker with a JSON parser and handles messages and errors to notify
   * the subscriber accordingly.
   * @param subscriber - The `subscriber` parameter in the constructor is an instance of the
   * `Subscriber` class with a generic type `IAnyObject`. This subscriber is used to send data,
   * complete the subscription, or handle errors in the context of the worker's processing.
   */
  constructor(private subscriber: Subscriber<IAnyObject>) {
    this.worker = new Worker(new URL('./json-parser.ts', import.meta.url));
    this.worker.onmessage = ({data}) => {
      if (data.type === 'data') {
        this.subscriber.next(data.data);
      } else if (data.type === 'complete') {
        this.subscriber.complete();
      } else {
        // else block
      }
    };

    this.worker.onerror = error => {
      this.subscriber.error(error);
    };
  }

  /**
   * The `parseChunk` function in TypeScript sends a chunk of data to a web worker using `postMessage`.
   * @param {string} chunk - The `chunk` parameter in the `parseChunk` function is a string that
   * represents a piece of data or information that needs to be processed or parsed.
   */
  parseChunk(chunk: string): void {
    this.worker.postMessage(chunk);
  }

  /**
   * The `complete` function sends a message 'complete' to a worker.
   */
  complete(): void {
    this.worker.postMessage('complete');
  }
}
