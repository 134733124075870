import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
@Injectable()
export class ToggleSwitchService {
  public readonly toggleSubject = new Subject<{
    checked: boolean;
    id: string;
    previousId?: string;
  }>();
  defaultSwitchVal = {checked: true, id: ''};
  public readonly toggleSelectData: BehaviorSubject<{
    checked: boolean;
    id: string;
    previousId?: string;
  }> = new BehaviorSubject(this.defaultSwitchVal);

  public setData(toggleData: {
    checked: boolean;
    id: string;
    previousId?: string;
  }) {
    this.toggleSubject.next(toggleData);
  }

  public setToggleSelectedData(toggleSelectData: {
    checked: boolean;
    id: string;
    previousId?: string;
  }) {
    this.toggleSelectData.next(toggleSelectData);
  }
}
