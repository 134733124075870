export class NameId {
  id: string | undefined;
  name: string | undefined;
  parentName?: string;
  avatar?: string;
  constructor(data?: Partial<NameId>) {
    this.id = data?.id;
    this.name = data?.name;
    this.parentName = data?.parentName;
    this.avatar = data?.avatar;
  }
}
