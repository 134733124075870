export class PlanCopyDto {
  id!: string;
  versionId!: string;
  chatChannelId?: string;
  version?: string;
  isPlanCopyOperation!: boolean;
  versionTag?: string;
  restore = false;
  name?: string;
}
