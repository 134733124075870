import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'simplifi-select-box',
  templateUrl: './select-box.component.html',
  styleUrl: './select-box.component.scss',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class SelectBoxComponent<T> {
  @Input() form!: NgForm;
  @Input() items: T[] = [];
  @Input() bindLabel = 'name';
  @Input() bindValue = 'value';
  @Input() id!: string;
  @Input() name!: string;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;
  @Input() inputLabel = true;
  @Input() horizontalField = false;
  @Input() readonly = false;
  @Input() multiple = false;
  @Input() selectableGroup = true;
  @Input() optionsWithIcons = false;
  @Input() searchable = false;
  @Input() selectModel!: string | undefined | number | number[] | string[];
  @Output() selectModelChange = new EventEmitter();
  @Input() validationMessage = '';
  onChange(event: Event) {
    this.selectModelChange.emit(event);
  }
}
