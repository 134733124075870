export type ColType = {
  CPM: string;
  Impressions: string;
  Budget: string;
  ZTV: string;
};

export class Totals {
  budget: number;
  spend: number;
  items: number;
  impressions: number;
  cpm: number;
  constructor() {
    this.budget = 0;
    this.spend = 0;
    this.items = 0;
    this.impressions = 0;
    this.cpm = 0;
  }
}
export type TacticKey = keyof PercentageType; // Ensure tactic.key is a key of PercentageType

export class PercentageType {
  Audience: string;
  Contextual: string;
  Keywords: string;
  ZTV: string;
  constructor() {
    this.Audience = '0';
    this.Contextual = '0';
    this.Keywords = '0';
    this.ZTV = '0';
  }
}

export class Percentages {
  'Budget': PercentageType;
  'CPM': PercentageType;
  'Impressions': PercentageType;
  'TargetCount': PercentageType;
}

export class OverAllTotals {
  budget: number;
  impressions: number;
  cpm: number;
  items: number;
  totals: number;
  constructor() {
    this.budget = 0;
    this.impressions = 0;
    this.cpm = 0;
    this.items = 0;
    this.totals = 0;
  }
}
