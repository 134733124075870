import {Injectable} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {catchError, Observable, of, tap} from 'rxjs';

import {AuthService} from '../auth.service';
import {environment} from '@simplifi/env/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate: CanActivateFn = (): Observable<boolean> => {
    return this.authService.isLoggedIn().pipe(
      tap(res => {
        if (res) {
          this.router.navigate([environment.homePath]);
        }
      }),
      catchError(() => of(true)),
    );
  };
}
