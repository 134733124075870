import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class DeleteService {
  private readonly deleteSubject = new Subject<string>();
  deleteData = this.deleteSubject.asObservable();

  public setData(deleteData: string) {
    this.deleteSubject.next(deleteData);
  }
}
