<div class="dialog-main-wrapper">
  <div mat-dialog-title class="el-dialog__header">
    <span class="el-dialog__title"> Save Progress As: </span>
    <button
      type="button"
      aria-label="Close"
      class="el-dialog__headerbtn"
      (click)="onClick(false)"
    >
      <i class="el-dialog__close el-icon el-icon-close"></i>
    </button>
  </div>
  <mat-dialog-content>
    <form class="el-form plan-form" #form="ngForm">
      <simplifi-input-box
        class="block"
        type="text"
        name="version-nickname"
        id="version-nickname"
        label="Version Nickname (Optional)"
        [(inputModel)]="versionTag"
        [form]="form"
      ></simplifi-input-box>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions
    class="form-action el-row--flex is-justify-space-between gap-x-2"
  >
    <div class="form-rhs-action el-row--flex is-justify-end gap-x-2">
      <simplifi-input-button
        [mat-dialog-close]="true"
        color="defaultText"
        [name]="'Cancel'"
        (click)="onClick(false)"
      ></simplifi-input-button>
      <simplifi-input-button
        type="basic"
        color="primary"
        [name]="'Save'"
        (click)="onClick(true)"
      ></simplifi-input-button>
    </div>
  </mat-dialog-actions>
</div>
