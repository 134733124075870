import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DeleteHelperService {
  private readonly deleteSubject = new BehaviorSubject<string>('');
  deletePlan = this.deleteSubject.asObservable();

  public deleteById(id: string) {
    this.deleteSubject.next(id);
  }

  public complete() {
    this.deleteSubject.complete();
  }
}
