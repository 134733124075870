import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[simplifiTrim]',
  standalone: true,
})
export class TrimDirective {
  constructor(private el: ElementRef) {}

  /**
   * Trim the input value on focus out of input component
   */
  @HostListener('focusout') onFocusOut() {
    (this.el.nativeElement as HTMLInputElement).value = (
      this.el.nativeElement as HTMLInputElement
    ).value.trim();
  }
}
