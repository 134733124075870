/* eslint-disable @typescript-eslint/no-explicit-any */
import {Component} from '@angular/core';
import {ICellRenderer} from '@simplifi/shared/modules';
import {ICellRendererAngularComp} from 'ag-grid-angular';
@Component({
  selector: 'simplifi-plan-target-cell-renderer',
  templateUrl: './id-column-cell-renderer.component.html',
  styleUrls: ['./id-coloumn-cell-renderer.component.scss'],
})
export class IdColumnCellRendererComponent implements ICellRendererAngularComp {
  constructor() {}
  icons: string[] = [];
  public params!: ICellRenderer;
  public shortId!: string;
  agInit(params: ICellRenderer): void {
    const colId = params.column.colId ?? 'id';
    this.params = params;
    this.shortId = String(params.data[colId]).substring(0, 8);
  }
  refresh() {
    return true;
  }
}
