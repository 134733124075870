import {Directive, ElementRef, HostListener} from '@angular/core';
import {PATTERNS} from '../constants';

@Directive({
  selector: '[simplifiNumber]',
  standalone: true,
})
export class NumberDirective {
  constructor(private el: ElementRef) {}

  /**
   * Prevent the default action (typing "e" into the number input)
   */
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    if (
      (this.el.nativeElement as HTMLInputElement).type === 'number' &&
      (event.key === 'e' || event.key === '-' || event.key === '+')
    ) {
      event.preventDefault();
    }
  }

  /**
   * Prevent the 2 decimal places into the number input)
   */
  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    if ((this.el.nativeElement as HTMLInputElement).type === 'number') {
      const value = this.el.nativeElement.value;
      const match = value.match(PATTERNS.floatOnlyTwoDecimalPattern); // Regular expression to match numbers with up to two decimal places

      if (!match) {
        this.el.nativeElement.value = value.substring(0, value.length - 1); // Remove the last character if it doesn't match the pattern
      }
    }
  }
}
