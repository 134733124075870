import {Component} from '@angular/core';
import {ICellRenderer} from '@simplifi/shared/modules';
import {ToggleSwitchService} from '@simplifi/shared/services';
import {ICellRendererAngularComp} from 'ag-grid-angular';
@Component({
  selector: 'simplifi-plan-target-cell-renderer',
  templateUrl: './switch-button-cell-renderer.component.html',
  styleUrls: ['./switch-button-cell-renderer.component.scss'],
})
export class SwitchButtonCellRendererComponent
  implements ICellRendererAngularComp
{
  constructor(private toggleSwitchService: ToggleSwitchService) {}
  icons: string[] = [];
  isToggleSelected = false;
  selectDetails: {id: string; checked: boolean; previousId?: string} = {
    id: '',
    checked: false,
    previousId: '',
  };
  public params!: ICellRenderer;
  agInit(params: ICellRenderer): void {
    this.params = params;
    this.toggleSwitchService.toggleSelectData.subscribe(value => {
      this.isToggleSelected = this.params.data.id === value.id;
      this.selectDetails.previousId = this.params.data.key;
      this.selectDetails = value;
    });
  }
  onSwitchClick() {
    this.selectDetails.id = this.params.data.key;
    this.toggleSwitchService.setData(this.selectDetails);
  }
  refresh() {
    return true;
  }
}
