export class BudgetMediaType {
  planVersionId!: string;
  targetMediaTypeId!: string;
  budgetAllocation = 0;
  cpm = 0;

  constructor(data: BudgetMediaType) {
    this.cpm = data.cpm;
    this.budgetAllocation = data.budgetAllocation;
    this.planVersionId = data.planVersionId;
    this.targetMediaTypeId = data.targetMediaTypeId;
  }
}
