export const NO_ROWS_TEMPLATE = `
    <div
        style="display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;">
            <span style="font-size: large;" class="icon-no-rows"></span>
            <span style="font-size: medium; margin-top: 10px">No ###data### Created</span>
    </div>
`;
