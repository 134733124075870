export class DonutData {
  labels: string[];
  datasets: {
    label: string;
    data?: number[];
    backgroundColor: string[];
    hoverBackgroundColor: string[];
    borderWidth: number;
  }[];
  donutTotalText?: string;
  donutTotalValue?: string;
  constructor() {
    this.labels = [];
    this.datasets = [
      {
        label: '',
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
        borderWidth: 0,
      },
    ];
    this.donutTotalText = '';
    this.donutTotalValue = '';
  }
}

export class DonutDataset {
  label!: string;
  chartLabels!: string[];
  data!: number[];
  backgroundColor!: string[];
  hoverBackgroundColor!: string[];
  borderWidth!: number;
  donutTotalText!: string;
  donutTotalValue!: string;
  donutDefaultConfig: DonutDefaultConfig;
  constructor() {
    this.label = 'Budget Breakdown';
    this.chartLabels = [];
    this.data = [];
    this.backgroundColor = ['#00D1FF', '#F58AB7', '#099B7F', '#754AD0'];
    this.hoverBackgroundColor = ['#00D1FF', '#F58AB7', '#099B7F', '#754AD0'];
    this.borderWidth = 0;
    this.donutTotalText = 'Total';
    this.donutTotalValue = '0';
    this.donutDefaultConfig = new DonutDefaultConfig();
  }
}

export class DonutDefaultConfig {
  fontSizeText: number;
  fontSizeValue: number;
  fillStyleText: string;
  fillStyleValue: string;
  constructor() {
    this.fontSizeText = 12;
    this.fontSizeValue = 24;
    this.fillStyleText = '#191919';
    this.fillStyleValue = '#191919';
  }
}
