import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

type InputType = 'text' | 'email' | 'password' | 'number' | 'date' | 'checkbox';
type stringInput = string | string[];
@Component({
  selector: 'simplifi-input-box',
  templateUrl: './input-box.component.html',
  styleUrls: ['./input-box.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class InputBoxComponent {
  @Input() form!: NgForm;
  @Input() type: InputType = 'text';
  @Input() label?: string;
  @Input() id!: string;
  @Input() name!: string;
  @Input() minlength!: number;
  @Input() maxlength!: number;
  @Input() inputLabel = true;
  @Input() hasSearch = false;
  @Input() color = 'primary';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() inputModel!: stringInput | boolean | undefined | Date | number;
  @Input() pattern!: string;
  @Input() width = '';
  @Input() maxWidth = '';
  @Input() prefixIcon = '';
  @Input() suffixIcon = '';
  @Input() prefixSvg = '';
  @Input() suffixSvg = '';
  @Input() horizontalField = false;
  @Input() inputHelpText = '';
  @Input() suffixLabelIcon = '';
  @Input() validationMessage = '';
  @Input() showTooltip = '';
  @Output() inputModelChange = new EventEmitter();

  onChange(event: Event) {
    this.inputModelChange.emit(event);
  }
}
