import {environment} from '@simplifi/env/environment';
import {GetAPICommand, IAdapter, IApiService} from '../../api';

export class GetCurrentUserCommand<T> extends GetAPICommand<T> {
  constructor(apiService: IApiService, adapter: IAdapter<T>) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.tenantUserFacade}/auth/me`,
    );
  }
}
