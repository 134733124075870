import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ApiModule} from './api/api.module';

import {StoreModule} from './store';
import {ToasterModule} from './toaster/toaster.module';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {ComponentBaseDirective} from './component-base';
import {HttpInterceptorProviders} from './interceptors';
import {CoreAuthModule} from './auth';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {environment} from '@simplifi/env/environment';

@NgModule({
  declarations: [ComponentBaseDirective],
  providers: [
    HttpInterceptorProviders,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaKey,
    },
  ],
  imports: [
    CommonModule,
    NgxPermissionsModule.forRoot(),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.ERROR,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    FormsModule,
    HttpClientModule,
    RouterModule,
    ApiModule,
    StoreModule,
    ToasterModule,
    CoreAuthModule,
    RecaptchaV3Module,
  ],
  exports: [StoreModule, ApiModule, ComponentBaseDirective],
})
export class CoreModule {
  // Ensure that CoreModule is only loaded into AppModule

  //  Looks for the module in the parent injector to see if
  //  it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `${parentModule.constructor.name} has already been loaded. Import this module in the AppModule only.`,
      );
    }
  }
}
