export class PlanResultSummary {
  id?: string;
  planVersionId?: string;
  locationCount?: number;
  totalHouseholds?: number;
  totalPopulations?: number;
  constructor() {
    this.id = '';
    this.planVersionId = '';
    this.locationCount = 0;
    this.totalHouseholds = 0;
    this.totalPopulations = 0;
  }
}
